import { Injectable } from '@angular/core';
import { Logger } from '../logger';
import { FileUtilsService, Unit } from '../file/file-utils.service';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '../toaster.service';
import { FileSizeError } from '../../models/errors/file/file-size-error';
import { FileFormatError } from '../../models/errors/file/file-format-error';
import * as exifr from 'exifr';
import piexif from 'piexifjs';

@Injectable({
  providedIn: 'root'
})
export class ImageResizerService {

  static readonly MAX_SIDE_LENGTH: number = 2500;
  static readonly MAX_FILE_SIZE_MB: number = 5;
  static readonly JPEG_QUALITY: number = 0.9;
  static readonly JPEG_TYPE: string = 'image/jpeg';
  static readonly PNG_TYPE: string = 'image/png';
  static readonly GIF_TYPE: string = 'image/gif';
  static readonly PHOTO_JPEG_TYPE: string = 'jpeg';
  static readonly PHOTO_PNG_TYPE: string = 'png';
  static readonly PHOTO_GIF_TYPE: string = 'gif';

  constructor(
    private fileUtilsService: FileUtilsService
  ) { }

  /**
   * Check if file type is supported
   * @return boolean
   */
  public isAuthorizedFormat(type: string): boolean {
    return type === ImageResizerService.PNG_TYPE || type === ImageResizerService.JPEG_TYPE || type === ImageResizerService.GIF_TYPE;
  }

  /**
   * Check if file type is supported
   * @return boolean
   */
  public isAuthorizedPhotoFormat(type: string): boolean {
    return type === ImageResizerService.PHOTO_PNG_TYPE || type === ImageResizerService.PHOTO_JPEG_TYPE || type === ImageResizerService.PHOTO_GIF_TYPE;
  }

  /**
   * Resize an image blob or base64 string (if necessary) and returns a downgraded JPEG of given image.
   * @param data Image to downgrade and potentially resize
   * @return Downgraded and potentially resized image data
   */
  async resize(data: Blob | string, format?: string): Promise<Blob> {
    let dataUrl = '';
    let originalBlob: Blob;
  
    if(typeof data === 'object') {
      if (!data) {
        throw new TypeError(`file is null or undefined`);
      }

      if (!this.isAuthorizedFormat(data.type)) {
        throw new FileFormatError(`file is not type of jpeg, png or gif`);
      }
      originalBlob = data;
      dataUrl = window.URL.createObjectURL(data);
    } else {
      if (!data) {
        throw new TypeError(`base64Data is null or undefined or empty`);
      }
      if (format && (!this.isAuthorizedPhotoFormat(format))) {
        throw new FileFormatError(`file is not type of jpeg, png or gif`);
      }
      dataUrl = data;
      // If base64 string, convert to Blob for metadata parsing
      const byteString = atob(data.split(',')[1]);
      const mimeString = data.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      originalBlob = new Blob([ab], { type: mimeString });
    }

    // Extract EXIF data
    const gpsExif = await exifr.gps(originalBlob);

    // Compute scale factors for width and height according to maximum pixel length for one side
    const image = await this.loadImageData(dataUrl);
    const widthScaleFactor = ~~(image.width / ImageResizerService.MAX_SIDE_LENGTH) + 1;
    const heightScaleFactor = ~~(image.height / ImageResizerService.MAX_SIDE_LENGTH) + 1;

    // Take greatest scale factor and compute downscaled final size
    const scaleFactor = Math.max(widthScaleFactor, heightScaleFactor);
    const downscaledWidth = Math.ceil(image.width / scaleFactor);
    const downscaledHeight = Math.ceil(image.height / scaleFactor);

    // Prepare canvas using downscaled size and draw resized image
    const canvas = document.createElement('canvas');
    canvas.width = downscaledWidth;
    canvas.height = downscaledHeight;
    canvas.getContext('2d').drawImage(image, 0, 0, downscaledWidth, downscaledHeight);

    // Get downgraded and resized image from canvas
    const downgradedImage = await this.loadDowngradedJPEG(canvas);

    if (this.fileUtilsService.convertBytesTo(downgradedImage.size, Unit.MB) > ImageResizerService.MAX_FILE_SIZE_MB) {
      throw new FileSizeError(`file after resize still bigger than 5MB`);
    }

    // Inject EXIF GPS data back (if available)
    if (gpsExif && gpsExif.latitude && gpsExif.longitude) {
      const base64Data = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (reader.result) resolve(reader.result as string);
          else reject('Failed to read resized blob');
        };
        reader.readAsDataURL(downgradedImage);
      });
  
      let stripped = base64Data.split(',')[1];
      let exifObj = { "GPS": {} };
  
      exifObj.GPS[piexif.GPSIFD.GPSLatitudeRef] = gpsExif.latitude >= 0 ? "N" : "S";
      exifObj.GPS[piexif.GPSIFD.GPSLatitude] = piexif.GPSHelper.degToDmsRational(Math.abs(gpsExif.latitude));
      exifObj.GPS[piexif.GPSIFD.GPSLongitudeRef] = gpsExif.longitude >= 0 ? "E" : "W";
      exifObj.GPS[piexif.GPSIFD.GPSLongitude] = piexif.GPSHelper.degToDmsRational(Math.abs(gpsExif.longitude));
  
      const exifBytes = piexif.dump(exifObj);
      const inserted = piexif.insert(exifBytes, base64Data);
  
      // Convert base64 back to Blob
      const byteString = atob(inserted.split(',')[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: ImageResizerService.JPEG_TYPE });
    }
  
    return downgradedImage;
  }

  /**
   * Load image data (async)
   * processing onload callback gives you back data access
   * @param url given url file
   * @return promise with image data loaded
   */
  private loadImageData(url: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.addEventListener('load', () => resolve(img));
      img.addEventListener('error', () => {
        reject(new Error(`failed to build HTMLImageElement from url`));
      });
      img.src = url;
    });
  }

  /**
   * Get downgraded JPEG Blob from canvas
   * @param canvas Canvas with resized image data
   * @return Blob with downgraded and resized JPEG image
   */
  private loadDowngradedJPEG(canvas: HTMLCanvasElement): Promise<Blob> {
    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          reject(new TypeError(`failed to convert canvas to blob, blob is null or undefined`));
        }
        resolve(blob);
      }, ImageResizerService.JPEG_TYPE, ImageResizerService.JPEG_QUALITY);
    });
  }
}
