import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalConstants } from '@constants/global-constants';
import { SummaryType } from '@services/export.service';
import { StripeService } from '@services/stripe.service';
import { NGXLogger } from 'ngx-logger';

type SuccessCallback = (form: FormGroup) => void;
type DismissCallback = () => void;

type summarySuccessCallback = (type: SummaryType, fromDate: Date, title: string, toDate: Date, selectedReportFields: string[]) => void;

export enum ExportReportFilter {
  PDF = 'PDF',
  CSV = 'CSV',
  EXCEL = 'EXCEL'
}
export enum ExportSummaryReportFilter {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  CUSTOM = 'custom'
}

export enum ReportFields {
  DIARY_ID = 'DIARY_ID',
  EVENT_TITLE = 'EVENT_TITLE',
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
  CONTRACTORS = 'CONTRACTORS',
  LOCATIONS = 'LOCATIONS',
  TAGS = 'TAGS',
  PHOTOS = 'PHOTOS',
  PEOPLE = 'PEOPLE',
  EQUIPMENT = 'EQUIPMENT',
  MATERIAL = 'MATERIAL',
}

@Component({
  selector: 'app-event-report-filter',
  templateUrl: './event-report-filter.component.html',
  styleUrls: ['./event-report-filter.component.scss'],
})
export class EventReportFilterComponent implements OnInit {

  @Input() private successCallback: SuccessCallback;
  @Input() private summarySuccessCallback: summarySuccessCallback;
  @Input() private dismissCallback: DismissCallback;
  @Input()  isSummaryPDF: boolean;
  @Input()  isSingleEventReport: boolean = false;

  reportType: string;
  inputPlaceholder: string = 'Site_Diary_Report_YYYY_MM_DD'

  public isDateDurationInvalid: boolean = false;
  public isDateRangeInvalid: boolean = false;
  public customSummaryEndDateInvalid: boolean = false;

  reportFilterForm: FormGroup;
  summaryPdfFilterForm: FormGroup;
  isXLSSubscribed: boolean;
  isCSVSubscribed: boolean;
  ExportReportFilter = ExportReportFilter;

  reportFormats = [
    { value: ExportReportFilter.PDF, id: 'pdf-report', label: 'events.export.pdf.label' },
    { value: ExportReportFilter.EXCEL, id: 'excel-report', label: 'events.export.excel.label' },
    { value: ExportReportFilter.CSV, id: 'csv-report', label: 'events.export.csv.label' }
  ];

  summaryReportFormats = [
    { value: ExportSummaryReportFilter.DAILY, id: 'daily-summary', label: 'events.export.pdf.summary.popup.type-daily-label' },
    { value: ExportSummaryReportFilter.WEEKLY, id: 'weekly-summary', label: 'events.export.pdf.summary.popup.type-weekly-label' },
    { value: ExportSummaryReportFilter.CUSTOM, id: 'custom-summary', label: 'events.export.pdf.summary.popup.type-custom-label' }
  ];

  mandatoryFields = [
    { label: 'events.export-report-diary-id' },
    { label: 'custom_event_form.mandatory_event_title' },
    { label: 'custom_event_form.mandatory_event_start_date' },
    { label: 'custom_event_form.mandatory_event_end_date' }
  ];

  summaryPdfMandatoryFields = [
    { label: 'events.list.site_name' },
    { label: 'label.date' },
    { label: 'summary-report.site_diary.prepared_by' },
    { label: 'report.weather' }
  ];

  activitiesFields = [
    { controlName: 'contractors', label: 'report.contractors' },
    { controlName: 'locations', label: 'report.location' },
    { controlName: 'tags', label: 'report.tags' },
    { controlName: 'photos', label: 'events.preview.section.photos' }
  ];

  summaryActivitiesFields = [
    { controlName: 'contractors', label: 'report.contractors' },
    { controlName: 'photos', label: 'events.preview.section.photos' }
  ];

  resourcesFields = [
    { controlName: 'people', label: 'report.people' },
    { controlName: 'equipment', label: 'report.equipment' },
    { controlName: 'material', label: 'report.materials' }
  ];

  constructor(
    private fb: FormBuilder,
    private stripeService: StripeService,
  ) {}

  ngOnInit() {
    this.reportFilterForm = this.fb.group({
      reportType: ['PDF', Validators.required],
      documentName: [''],
      contractors: [true],
      locations: [true],
      tags: [true],
      photos: [true],
      people: [true],
      equipment: [true],
      material: [true]
    });
    this.summaryPdfFilterForm = this.fb.group({
      reportType: ['daily', Validators.required],
      startDate: [null, Validators.required],
      endDate: [null],
      documentName: [''],
      contractors: [true],
      photos: [true],
      people: [true],
      equipment: [true],
      material: [true]
    });

    this.stripeService.currentSpaceHasFeature('REPORT_CSV').subscribe((feature) => this.isCSVSubscribed = feature);
    this.stripeService.currentSpaceHasFeature('REPORT_XLS').subscribe((feature) => this.isXLSSubscribed = feature);

    if (this.isSummaryPDF) {
      this.summaryPdfFilterForm.controls['startDate'].valueChanges.subscribe(startdate => {
        if (this.summaryPdfFilterForm.controls['reportType'].value === 'weekly') {
          if (startdate !== null)
          {
            this.summaryPdfFilterForm.controls['endDate'].setValue(this.addOneWeek(startdate));
          }
        }
      });
  
      this.summaryPdfFilterForm.controls['reportType'].valueChanges.subscribe(type => {
        if (type !== 'custom') {
          this.isDateRangeInvalid = false;
          this.isDateDurationInvalid = false;
          this.customSummaryEndDateInvalid = false;
        }
        if (type === 'weekly') {
          if (this.summaryPdfFilterForm.controls['startDate'].value !== null) {
            this.summaryPdfFilterForm.controls['endDate'].setValue(this.addOneWeek(this.summaryPdfFilterForm.controls['startDate'].value));
          } else if (this.summaryPdfFilterForm.controls['endDate'].value !== null && this.summaryPdfFilterForm.controls['startDate'].value === null) {
            this.summaryPdfFilterForm.controls['endDate'].setValue(null);
          }
        } else if (type === 'daily') {
          this.summaryPdfFilterForm.controls['endDate'].setValue(null);
        }
      });

      this.summaryPdfFilterForm.valueChanges.subscribe(form => {
        if (form.reportType === 'custom') {
          if (form.endDate !== null && form.startDate !== null) {
            this.customSummaryEndDateInvalid = false;
            const strDateObject = new Date(form.startDate);
            const endDateObject = new Date(form.endDate);
            this.isDateDurationInvalid = (form.startDate > form.endDate);
            this.isDateRangeInvalid = (Math.abs(strDateObject.getTime() - endDateObject.getTime()) / (1000 * 60 * 60 * 24) > 30);
          } else if (form.endDate === null) {
            this.customSummaryEndDateInvalid = true;
          }
        }
      })
  
    }
  }

  private addOneWeek(strDate: string): string {
    const addedDate = new Date(new Date(strDate).getTime() + GlobalConstants.WEEK_MS);
    const date = addedDate.getDate();
    const month = addedDate.getMonth();
    const year = addedDate.getFullYear();
    return `${year}-${month + 1}-${date}`;
  }

  isSelectedFeatureAvailable(format: string): boolean {
    if (format === ExportReportFilter.EXCEL) {
      return this.isXLSSubscribed;
    } else if (format === ExportReportFilter.CSV) {
      return this.isCSVSubscribed;
    }
    return true;
  }

  isFeaturePaid(format: string) {
    return ((this.reportFilterForm.value['reportType'] === format) && !this.isSelectedFeatureAvailable(format));
  }

  isExportButtonDisabled(): boolean {
    if (this.isSummaryPDF) {
      return (this.summaryPdfFilterForm.invalid || this.isDateRangeInvalid || this.isDateDurationInvalid || this.customSummaryEndDateInvalid);
    } else if (this.reportFilterForm.value['reportType'] === ExportReportFilter.EXCEL) {
      return !this.isSelectedFeatureAvailable(ExportReportFilter.EXCEL) || this.reportFilterForm.invalid;
    } else if (this.reportFilterForm.value['reportType'] === ExportReportFilter.CSV) {
      return !this.isSelectedFeatureAvailable(ExportReportFilter.CSV) || this.reportFilterForm.invalid;
    } else {
      return this.reportFilterForm.invalid;
    }
  }

  getTooltipText(): string {
    return (this.customSummaryEndDateInvalid && this.summaryPdfFilterForm.controls['startDate'].value !== null) ? ('form.error.event-and-task.export-tooltip') : '';
  }

  setCallbacks(
    success: SuccessCallback,
    dismiss?: DismissCallback,
  ): void {
    this.successCallback = success;
    this.dismissCallback = dismiss;
  }

  setSummaryCallbacks(
    success: summarySuccessCallback,
    dismiss?: DismissCallback,
  ): void {
    this.summarySuccessCallback = success;
    this.dismissCallback = dismiss;
  }

  dismiss(): void {
    if (this.dismissCallback) {
      this.dismissCallback();
    }
  }

  download(): void {
    if (this.isSummaryPDF) {
      const requiredFields = ['contractors', 'equipment', 'material', 'people', 'photos'];
      const startDate = this.summaryPdfFilterForm.value['startDate'];
      const endDate = this.summaryPdfFilterForm.value['endDate'];
      const newDate = new Date(startDate);
      newDate.setHours(0, 0, 0, 0); // Resets hours, minutes, seconds, and milliseconds
      let newEndDate;
      if (endDate) {
        newEndDate = new Date(endDate);
        newEndDate.setHours(0, 0, 0, 0);
      }
      const type = this.summaryPdfFilterForm.value['reportType'];
      const title = this.summaryPdfFilterForm.value['documentName'];
      const selectedReportFields = Object.entries(this.summaryPdfFilterForm.value)
        .filter(([key, value]) => requiredFields.includes(key) && value)
        .map(([key]) => ReportFields[key.toUpperCase()]);
      this.summarySuccessCallback(type, newDate, title, newEndDate, selectedReportFields);
    } else {
      this.successCallback(this.reportFilterForm);
    }
  }
}
