import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
// Angular Material
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ui-web-time-picker',
  styleUrls: ['ui-web-time-picker.component.scss'],
  templateUrl: 'ui-web-time-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiWebTimePickerComponent implements OnInit {

  hours: number[] = [];
  selectedHour: number = 0;
  minutes: number[] = [0, 15, 30, 45];
  selectedMinute: number = 0;
  duration: number = 0;

  constructor(
    private dialogRef: MatDialogRef<UiWebTimePickerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {duration: number}
  ) {}

  ngOnInit(): void {
    for (let i = 0; i < 24; i++) {
      this.hours.push(i);
    }
    if(this.data.duration > 0) {
      this.selectedHour = Math.floor(this.data.duration / 60);
      this.selectedMinute = this.data.duration % 60;
      this.duration = this.data.duration;
    }
  }

  selectHour(hour: string): void {
    this.selectedHour = parseInt(hour);
  }

  selectMinute(minute: string): void {
    this.selectedMinute = parseInt(minute);
  }

  save(): void {
    this.duration = (this.selectedHour*60) + this.selectedMinute;
  }

  close(): void {
    this.dialogRef.close(this.duration);
  }

  cancel(): void {
    this.close();
  }

  saveAndClose(): void {
    this.save();
    this.close();
  }
}
