<form [formGroup]="isSummaryPDF? summaryPdfFilterForm : reportFilterForm" (ngSubmit)="download()" class="container">
  <p *ngIf="!isSummaryPDF" class="header"> {{ 'events.exporting-report' | translate }} </p>
  <p *ngIf="isSummaryPDF" class="header"> {{ 'events.exporting-summary-report' | translate }} </p>
  <div class="sub-details">
    <p class="sub-header"> {{ 'events.export-report-general' | translate }} </p>
    <hr>
    <p *ngIf="!isSummaryPDF && !isSingleEventReport" class="field-header"> {{ 'events.export-report-format' | translate }} </p>
    <p *ngIf="isSummaryPDF && !isSingleEventReport" class="field-header"> {{ 'events.export-summary-report-format' | translate }} </p>
    <div *ngIf="!isSummaryPDF && !isSingleEventReport" class="report-options">
      <div class="options" *ngFor="let format of reportFormats">
        <input type="radio" formControlName="reportType" [value]="format.value" [id]="format.id">
        <label [for]="format.id"> {{ format.label | translate }} </label>
      </div>
    </div>

    <div *ngIf="!isSummaryPDF && !isSingleEventReport && isFeaturePaid(ExportReportFilter.EXCEL)" class="report-options">
      <p class="subscription-error">{{ 'upgrade.feature.REPORT_XLS' | translate }}</p>
    </div>

    <div *ngIf="!isSummaryPDF && !isSingleEventReport && isFeaturePaid(ExportReportFilter.CSV)" class="report-options">
      <p class="subscription-error">{{ 'upgrade.feature.REPORT_CSV' | translate }}</p>
    </div>

    <div *ngIf="isSummaryPDF && !isSingleEventReport" class="report-options">
      <div class="options" *ngFor="let format of summaryReportFormats">
        <input type="radio" formControlName="reportType" [value]="format.value" [id]="format.id">
        <label [for]="format.id"> {{ format.label | translate }} </label>
      </div>
    </div>

    <div *ngIf="isSummaryPDF" class="date-fields">
      <div class="startDate">
        <label for="startdate" class="titles">
          {{'events.export.pdf.summary.popup.startdate-label' | translate}}
        </label>
        <div class="date-input">
          <app-datetime-input #startDate (click)="startDate.openDatetimePicker()" [type]="'startDatetime'" [hideTime]="true"
            placeholder="{{'mobile-placeholder-startdate' | translate}}" formControlName="startDate">
          </app-datetime-input>
        </div>
      </div>

      <div class="endDate-container">
        <label for="enddate" *ngIf="summaryPdfFilterForm.controls['reportType'].value === 'custom'" class="titles">
          {{'events.export.pdf.summary.popup.enddate-label' | translate}}
        </label>
        <label for="enddate" *ngIf="summaryPdfFilterForm.controls['reportType'].value === 'weekly'" class="titles">
          {{'tasks.detail.panel_details.end_date' | translate}}
        </label>
        <div class="date-input">
          <app-datetime-input #endDate (click)="endDate.openDatetimePicker()" [type]="'endDatetime'" [hideTime]="true" [hidden]="summaryPdfFilterForm.controls['reportType'].value === 'daily'"
            placeholder=" " formControlName="endDate" [readonly]="(summaryPdfFilterForm.controls['reportType'].value !== 'custom'? true: false)">
          </app-datetime-input>
        </div>
      </div>
    </div>
    <div *ngIf="isDateDurationInvalid" class="flex-row justify-content-end">
      <span class="validation-error text-danger">
        {{'form.error.event-and-task.negativeDuration' | translate}}
      </span>
    </div>
    <div *ngIf="isDateRangeInvalid && !isDateDurationInvalid">
      <span class="validation-error text-danger">
        {{'events.report.error.one_month_duration' | translate}}
      </span>
    </div>
    
    <p class="field-header" *ngIf="reportFilterForm.controls['reportType'].value === 'PDF'"> {{ 'events.export.pdf.events.popup.title-label.short' | translate }} </p>
    <input *ngIf="reportFilterForm.controls['reportType'].value === 'PDF'" type="text" class="document-name" formControlName="documentName" [placeholder]="inputPlaceholder">
  </div>
  
  <div class="sub-details">
    <p class="sub-header"> {{ 'events.export-report-data' | translate }} </p>
    <hr>
    <p class="field-header"> {{ 'events.section_title.mandatory_information' | translate }}</p>
    <div *ngIf="!isSummaryPDF" class="mandatory-options">
      <label *ngFor="let field of mandatoryFields">
        <input type="checkbox" checked disabled> {{ field.label | translate }}
      </label>
    </div>
    <div *ngIf="isSummaryPDF" class="mandatory-options">
      <label *ngFor="let field of summaryPdfMandatoryFields">
        <input type="checkbox" checked disabled> {{ field.label | translate }}
      </label>
    </div>
    
    <p class="field-header"> {{ 'report.site_diary.activities' | translate }} </p>
    <div class="mandatory-options">
      <label *ngFor="let field of isSummaryPDF ? summaryActivitiesFields : activitiesFields">
        <input type="checkbox" [formControlName]="field.controlName"> {{ field.label | translate }}
      </label>
    </div>
    
    <p class="field-header"> {{ 'report.resources' | translate }} </p>
    <div class="mandatory-options">
      <label *ngFor="let field of resourcesFields">
        <input type="checkbox" [formControlName]="field.controlName"> {{ field.label | translate }}
      </label>
    </div>
  </div>
  
  <div class="action-buttons">
    <button type="button" (click)="dismiss()" class="cancel-button">
      {{ 'events.report.btn.cancel' | translate }}
    </button>
    <button type="submit" class="export-button" [disabled]="isExportButtonDisabled()">
      <div
        [matTooltip]="getTooltipText() | translate"
        matTooltipPosition="above"
        matTooltipClass="my-tooltip">
        {{ 'btn.export' | translate }}
      </div>
    </button>
  </div>
</form>
