<ng-container>
  <ion-header class="secondary-header" mode="ios">
    <app-mobile-toolbar
      doneLabel="btn.export"
      title="Export Report"
      [disableDoneButton]="isExportButtonDisabled()"
      (back)="dismiss()"
      (done)="download()">
    </app-mobile-toolbar>
  </ion-header>

  <ion-content class="main-form-wrapper whitesmoke-background">
    <form [formGroup]="formGroup">
      
      <!-- General Section -->
      <ion-item-divider>
        <ion-label
          (click)="isCollapsedGeneral = !isCollapsedGeneral"
          [attr.aria-expanded]="!isCollapsedGeneral"
          aria-controls="collapseGeneral">
          <h1 class="pull-left">{{ 'events.export-report-general' | translate }}</h1>
          <ion-icon class="pull-right" [attr.name]="isCollapsedGeneral ? 'chevron-down' : 'chevron-up'"></ion-icon>
        </ion-label>
      </ion-item-divider>

      <div class="seperator"></div>
      
      <div id="collapseGeneral" [ngbCollapse]="isCollapsedGeneral">
        <!-- Format -->
        <ion-item-divider *ngIf="!isSingleEvent">
          <ion-label>
            <h2>{{ 'events.export-report-format' | translate }}</h2>
          </ion-label>
        </ion-item-divider>
        <ion-list *ngIf="!isSingleEvent">
          <ion-radio-group formControlName="format">
            <ion-item lines="none">
              <ion-radio mode="md" value="PDF" checked></ion-radio>
              <ion-label>{{ 'events.export.pdf.label' | translate }}</ion-label>
            </ion-item>
            <ion-item lines="none">
              <ion-radio mode="md" value="EXCEL"></ion-radio>
              <ion-label>{{ 'events.export.excel.label' | translate }}</ion-label>
            </ion-item>
            <ion-item lines="none">
              <ion-radio mode="md" value="CSV"></ion-radio>
              <ion-label>{{ 'events.export.csv.label' | translate }}</ion-label>
            </ion-item>
          </ion-radio-group>
        </ion-list>
        <div *ngIf="!isSingleEvent && isFeaturePaid(ExportReportFilter.EXCEL)" class="report-warning">
          <p>{{ 'upgrade.feature.REPORT_XLS' | translate }}</p>
        </div>
    
        <div *ngIf="!isSingleEvent && isFeaturePaid(ExportReportFilter.CSV)" class="report-warning">
          <p>{{ 'upgrade.feature.REPORT_CSV' | translate }}</p>
        </div>

        <!-- Document Name -->
        <ion-item-divider *ngIf="isReportPdf">
          <ion-label>
            <h2>{{ 'events.export.pdf.events.popup.title-label.short' | translate }}</h2>
          </ion-label>
        </ion-item-divider>
        <ion-item *ngIf="isReportPdf">
          <ion-input formControlName="documentName" placeholder="Enter document name" type="text"></ion-input>
        </ion-item>
      </div>

      <ion-item-divider [ngbCollapse]="isCollapsedGeneral" *ngIf="isReportPdf && !isSingleEvent">
        <ion-label>
          <h2>{{'events.export.pdf.summary.popup.dates-label' | translate}}</h2>
        </ion-label>
      </ion-item-divider>
      <div id="collapseDate" [ngbCollapse]="isCollapsedGeneral" *ngIf="isReportPdf && !isSingleEvent">
        <ion-item class="form-input" lines="none" (click)="startDate.openDatetimePicker()">
          <ion-col size="1" class="form-input-icon ion-no-padding">
            <ion-label color="medium">
              <ion-icon color="primary" class="pull-left" name="custom-calendar"></ion-icon>
            </ion-label>
          </ion-col>
          <ion-col>
            <app-datetime-input #startDate [readonly]="false" [type]="'startDatetime'" [hideTime]="true" [hideShortcut]="true"
              placeholder="{{'events.export.pdf.summary.popup.startdate-label' | translate}}" formControlName="startDatetime">
            </app-datetime-input>
          </ion-col>
        </ion-item>
        <ion-item class="form-input" lines="none" (click)="endDate.openDatetimePicker()">
          <ion-col size="1" class="form-input-icon ion-no-padding">
            <ion-label color="medium">
              <ion-icon color="primary" class="pull-left" name="custom-calendar"></ion-icon>
            </ion-label>
          </ion-col>
          <ion-col>
            <app-datetime-input #endDate  [readonly]="false" [type]="'endDatetime'" [hideTime]="true" [hideShortcut]="true"
              placeholder="{{'events.export.pdf.summary.popup.enddate-label' | translate}}" formControlName="endDatetime">
            </app-datetime-input>
          </ion-col>
        </ion-item>
      </div>

      <!-- Data Section -->
      <ion-item-divider>
        <ion-label (click)="isCollapsedData = !isCollapsedData" [attr.aria-expanded]="!isCollapsedData" aria-controls="collapseData">
          <h1 class="pull-left">{{ 'events.export-report-data' | translate }}</h1>
          <ion-icon class="pull-right" [attr.name]="isCollapsedData ? 'chevron-down' : 'chevron-up'"></ion-icon>
        </ion-label>
      </ion-item-divider>

      <div class="seperator"></div>
      
      <div id="collapseData" [ngbCollapse]="isCollapsedData">
        <!-- Mandatory Information -->
        <ion-item-divider>
          <ion-label><h2>{{ 'custom_event_form.mandatory_information' | translate }}</h2></ion-label>
        </ion-item-divider>
        <ion-list>
          <ion-item lines="none" *ngFor="let item of mandatoryInfo">
            <ion-checkbox checked="true" disabled="true"></ion-checkbox>
            <ion-label>{{ item.label | translate }}</ion-label>
          </ion-item>
        </ion-list>

        <!-- Activities Information -->
        <ion-item-divider>
          <ion-label><h2>{{ 'report.site_diary.activities' | translate }}</h2></ion-label>
        </ion-item-divider>
        <ion-list>
          <ion-item lines="none" *ngFor="let item of activitiesInfo">
            <ion-checkbox 
              [checked]="activitiesInformation.value.includes(item.controlName)" 
              (ionChange)="toggleSelection(activitiesInformation, item.controlName, $event)">
            </ion-checkbox>
            <ion-label>{{ item.label | translate }}</ion-label>
          </ion-item>
        </ion-list>

        <!-- Resources -->
        <ion-item-divider>
          <ion-label><h2>{{ 'menu.resources' | translate }}</h2></ion-label>
        </ion-item-divider>
        <ion-list>
          <ion-item lines="none" *ngFor="let item of resources">
            <ion-checkbox 
              [checked]="resourcesArray.value.includes(item.controlName)" 
              (ionChange)="toggleSelection(resourcesArray, item.controlName, $event)">
            </ion-checkbox>
            <ion-label>{{ item.label | translate }}</ion-label>
          </ion-item>
        </ion-list>
      </div>
    </form>
  </ion-content>
</ng-container>

