import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';

import { EventsFilters } from '@models/events-filters';
import { ExportService, SummaryType } from '@services/export.service';
import { SpinnerService } from '@services/spinner.service';
import { PdfExportConfigurationPopupComponent } from '../event/event-list/pdf-export-configuration-popup/pdf-export-configuration-popup.component';
import { SummaryConfigurationPopupMobileComponent } from '../event/event-list/summary-configuration-popup/summary-configuration-popup-mobile/summary-configuration-popup-mobile.component';
import { EventService } from '@services/event.service';
import { FilterEvents } from 'app/pipes/filter-events.pipe';
import { Event } from '@models/event';
import { ReportExportConfigurationPopupComponent } from '../event/event-list/report-export-configuration-popup/report-export-configuration-popup.component';
import { ExportReportFilter, ReportFields } from '../event/event-list/event-report-filter/event-report-filter.component';

@Component({
  selector: 'app-export-selector-popup',
  templateUrl: './export-selector-popup.component.html',
  styleUrls: ['./export-selector-popup.component.sass']
})
export class ExportSelectorPopupComponent implements OnInit {

  /**
   * All events filters
   */
  eventsFilters: EventsFilters;
  includedEvents: string[];
  listOfEvents: Event[];
  searchInput: string;

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private spinnerService: SpinnerService,
    private exportService: ExportService,
    private eventService: EventService,
    private filterEventsPipe: FilterEvents,
  ) {  }

  ngOnInit() {
    this.eventsFilters = this.navParams.get('eventsFilters');
  }

  ignoreChanges() {
    this.modalCtrl.dismiss();
  }

  async openPdfSummaryModal(): Promise<void> {
    this.exportService.checkSubscription('REPORT_SUMMARY').subscribe(async () => {
      const modal = await this.modalCtrl.create({
        component: SummaryConfigurationPopupMobileComponent,
        componentProps: {
          mobileSuccessCallback: (type, fromDate, title, toDate, selectedReportFields) => {
            this.generateSummaryPdf(type, fromDate, title, toDate, selectedReportFields);
            modal.dismiss();
          },
          dismissCallback: () => modal.dismiss(),
        },
      });
      return await modal.present();
    });
  }

  generateSummaryPdf(type: SummaryType, fromDate: Date, title: string, toDate: Date, selectedReportFields: string[]): void {
    this.spinnerService.activate('rotating');
    this.exportService.fetchAndSaveEventSummaryPDF(type, fromDate, title, toDate, selectedReportFields).subscribe(
      () => this.spinnerService.deactivate(),
      error => this.spinnerService.deactivate(),
    );
  }

  async openPdfModal(): Promise<void> {
    this.exportService.checkSubscription('REPORT_PDF').subscribe(async () => {
      const modal = await this.modalCtrl.create({
        component: PdfExportConfigurationPopupComponent,
        componentProps: {
          successCallback: (result, startDatetime, endDatetime) => {
            this.getPdfReport(result, startDatetime, endDatetime);
            modal.dismiss();
          },
          dismissCallback: () => modal.dismiss(),
        },
      });
      return await modal.present();
    });
  }

  getPdfReport(title: string, fromDate: Date, toDate: Date, selectedReportFields?: string[]): void {
    this.spinnerService.activate('rotating');
    this.exportService.fetchAndSavePdfReport(
      fromDate,
      toDate,
      // Sending the filtered list of event id for a given date range
      this.filterEventsPipe.transformOnDates(
        this.listOfEvents,
        this.eventsFilters,
        this.searchInput,
        fromDate,
        toDate
      ).map(event => event.id),
      title,
      selectedReportFields
    ).subscribe(
      () => this.spinnerService.deactivate(),
      error => this.spinnerService.deactivate(),
    );
  }

  openReportFilterModal() {
    this.exportService.checkSubscription('REPORT_PDF').subscribe(async () => {
      const modal = await this.modalCtrl.create({
        component: ReportExportConfigurationPopupComponent,
        componentProps: {
          successCallback: (result) => {
            let selectedReportFields = [ReportFields.DIARY_ID, ReportFields.EVENT_TITLE, ReportFields.START_DATE, ReportFields.END_DATE]
              for (let information of result.value['activitiesInformation']) {
                selectedReportFields.push(information.toUpperCase())
              }
              for (let resource of result.value['resources']) {
                selectedReportFields.push(resource.toUpperCase());
              }
            if (result.value['format'] === ExportReportFilter.EXCEL) {
              this.getXlsReport(selectedReportFields)
            } else if (result.value['format'] === ExportReportFilter.CSV) {
              this.getCsvReport(selectedReportFields)
            } else if (result.value['format'] === ExportReportFilter.PDF) {
              const startDate = new Date(result.value['startDatetime']);
              const endDate = new Date(result.value['endDatetime']);
              const title = result.value['documentName'];
              this.getPdfReport(title, startDate, endDate, selectedReportFields);
            }
            modal.dismiss();
          },
          dismissCallback: () => modal.dismiss(),
        },
      });
      return await modal.present();
    });
  }

  getCsvReport(selectedReportFields: string[]): void {
    this.exportService.checkSubscription('REPORT_CSV').subscribe(() => {
      this.spinnerService.activate('rotating');
      this.exportService.fetchAndSaveCsvReport(
        this.includedEvents,
        selectedReportFields
      ).subscribe(
        () => this.spinnerService.deactivate(),
        error => this.spinnerService.deactivate(),
      );
    });
  }

  getXlsReport(selectedReportFields: string[]): void {
    this.exportService.checkSubscription('REPORT_XLS').subscribe(() => {
      this.spinnerService.activate('rotating');
      this.exportService.fetchAndSaveXlsReport(
        this.eventsFilters.dateFrom,
        this.eventsFilters.dateTo,
        this.includedEvents,
        selectedReportFields
      ).subscribe(
        () => this.spinnerService.deactivate(),
        error => this.spinnerService.deactivate(),
      );
    });
  }
}
