import { Component, OnInit } from '@angular/core';
import { Camera, ImageOptions, CameraResultType, CameraSource, Photo, GalleryImageOptions } from '@capacitor/camera';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ImageResizerService } from '@services/image/image-resizer.service';
import { StripeService } from '@services/stripe.service';
import { NGXLogger } from 'ngx-logger';
import { first, flatMap, map } from 'rxjs/operators';
import { ImagesInputComponent } from '../../../../../form/controls/shared/images-input/images-input.component';
import { Attachment } from '../../../../../models/attachment';
import { AndroidPermissionService } from '../../../../../services/android-permission.service';
import { DeviceService } from '../../../../../services/device.service';
import { FileTransfertService } from '../../../../../services/file-transfert.service';
import { UpgradeService } from '../../../../../subscription/upgrade.service';
import { SpinnerService } from '@services/spinner.service';
import { ToasterService } from '@services/toaster.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import * as piexif from 'piexifjs'
import { Directory, Filesystem } from '@capacitor/filesystem';

@Component({
  selector: 'app-mobile-gallery-popup',
  templateUrl: './mobile-gallery-popup.component.html',
  styleUrls: ['./mobile-gallery-popup.component.sass']
})
export class MobileGalleryPopupComponent implements OnInit {
  static readonly PICKER_QUALITY: number = 95;

  maximumImagesCount: number;
  isNewEvent: boolean;
  readOnly: boolean;
  imagesInputComponent: ImagesInputComponent;

  // ngx-viewer options
  public viewerOptions: any = {
    navbar: false,
    toolbar: false,
    transition: false
  };

  public showImageEditor: boolean = false;
  public editingImage: string | ArrayBuffer = '';
  public currentAttachmentId: string = ''; // Attachment which is being edited

  get attachments(): Attachment[] {
    return this.imagesInputComponent.attachments;
  }

  set attachments(value) { }

  constructor(private navParams: NavParams,
    private modalCtrl: ModalController,
    private fileTransfertService: FileTransfertService,
    private imagePicker: ImagePicker,
    public deviceService: DeviceService,
    public androidPermissionService: AndroidPermissionService,
    public stripeService: StripeService,
    private translateService: TranslateService,
    private upgradeService: UpgradeService,
    private logger: NGXLogger,
    private imageResizerService: ImageResizerService,
    private spinnerService: SpinnerService,
    private toasterService: ToasterService,
    private geolocation: Geolocation
  ) {
    this.imagesInputComponent = this.navParams.get('imagesInputComponent');
    this.readOnly = this.navParams.get('readonly');
    this.isNewEvent = this.navParams.get('isNewEvent');
  }

  ngOnInit() { }

  /**
   * Method called on click of back button to cancel changes
   */
  ignoreChanges() {
    this.modalCtrl.dismiss();
  }

  /**
   * Method called on click of ok button to close popup
   */
  acceptChanges() {
    this.modalCtrl.dismiss();
  }

  /**
   * Method calling ionic native camera to take a picture
   */
  takePictureFromCamera() {
    if (this.deviceService.isMobile) {
      this.androidPermissionService.checkCameraPermission('mobile-camera-permission-missing')
        .then(hasPermission => {
          if (!hasPermission) {
            this.logger.error('Unable to get camera permission.');
            throw new Error('Unable to get camera permission.');
          }
          this.logger.debug('User opened camera from mobile device');

          const options: ImageOptions = {
            quality: MobileGalleryPopupComponent.PICKER_QUALITY,
            source: CameraSource.Camera,
            saveToGallery: false,
            resultType: CameraResultType.DataUrl,
            correctOrientation: true,
          };
          this.spinnerService.activate('rotating');
          return Camera.getPhoto(options);
        })
        .then(async (photo: Photo) => {
          if (!photo.dataUrl) {
            throw new Error('Photo dataUrl is undefined.');
          }

          // Get current location
          const coordinates = await this.geolocation.getCurrentPosition();
          const latitude = coordinates.coords.latitude;
          const longitude = coordinates.coords.longitude;
          this.logger.debug(`Exif Location obtained: ${latitude}, ${longitude}`);

          // Embed location metadata into EXIF
          const updatedDataUrl = this.addGpsMetadataToDataUrl(photo.dataUrl, latitude, longitude);

          photo.dataUrl = updatedDataUrl;

          this.logger.error('abcd', photo);

          await this.saveImageToGallery(updatedDataUrl);

          this.logger.debug('EXIF metadata added successfully.');
          

          this.spinnerService.deactivate();

          // Convert photo into attachment after EXIF modification
          return this.imagesInputComponent.convertPhotoIntoAttachment(photo);
        })
        .catch(error => {
          this.logger.error('Exif Failed to upload an image from camera: ', error);
        });
    }
  }

  // Helper function to add GPS metadata to base64 image
  addGpsMetadataToDataUrl(dataUrl: string, lat: number, lng: number): string {
    try {
      // Get EXIF data
      const exifObj = piexif.load(dataUrl);

      // Convert latitude and longitude to EXIF format
      const gpsData = {
        [piexif.GPSIFD.GPSLatitudeRef]: lat >= 0 ? 'N' : 'S',
        [piexif.GPSIFD.GPSLatitude]: this.convertToExifFormat(Math.abs(lat)),
        [piexif.GPSIFD.GPSLongitudeRef]: lng >= 0 ? 'E' : 'W',
        [piexif.GPSIFD.GPSLongitude]: this.convertToExifFormat(Math.abs(lng)),
      };
      
      // Inject GPS data into EXIF
      exifObj['GPS'] = gpsData;
      
      // Generate new EXIF data and insert it into the image
      const exifStr = piexif.dump(exifObj);
      const updatedDataUrl = piexif.insert(exifStr, dataUrl);

      this.logger.error('EXIF original', dataUrl);
      this.logger.error('EXIF updated', updatedDataUrl);
      return updatedDataUrl;
    } catch (error) {
      this.logger.error('Error adding GPS metadata:', error);
      return dataUrl; // Return original if error occurs
    }
  }

  // Helper function to convert decimal coordinates to EXIF format
  convertToExifFormat(value: number): number[][] {
    const deg = Math.floor(value);
    const min = Math.floor((value - deg) * 60);
    const sec = ((value - deg - min / 60) * 3600) * 100;
    return [[deg, 1], [min, 1], [Math.round(sec), 100]];
  }

  async saveImageToGallery(base64DataUrl: string) {
    try {
      // Convert Base64 to Blob
      const blob = this.base64ToBlob(base64DataUrl);
      
      // Save the file to the device storage
      const fileName = `photo_${new Date().getTime()}.jpg`;
      await Filesystem.writeFile({
        path: fileName,
        data: base64DataUrl.split(',')[1], // Remove "data:image/jpeg;base64," prefix
        directory: Directory.Documents, // Save to external storage
      });
      this.logger.debug(`Image saved to gallery: ${fileName}`);
    } catch (error) {
      this.logger.error('Error saving image to gallery:', error);
    }
  }

  // Helper function to convert Base64 to Blob
  base64ToBlob(base64DataUrl: string): Blob {
    const byteCharacters = atob(base64DataUrl.split(',')[1]);
    const byteArrays = [];
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }
    return new Blob([new Uint8Array(byteArrays)], { type: 'image/jpeg' });
  }

  private async openMobileFilePicker(): Promise<void> {
    /*
     * On Android, the native file input does not work as expected and does not allow multiple selection.
     * On iOS, the cordova file picker causes the app to freeze and requires the app to be force stopped.
     * For this reason, we need to have the two methods in parallel.
     */
    if (this.deviceService.isAndroid) {
      try {
        let imagePaths = await this.imagePicker.getPictures({
          quality: MobileGalleryPopupComponent.PICKER_QUALITY,
          maximumImagesCount: this.maximumImagesCount,
        });
        if (this.maximumImagesCount && imagePaths.length > this.maximumImagesCount) {
          this.annoyUser();
          imagePaths = imagePaths.slice(this.maximumImagesCount);
        }
        const images = await this.fileTransfertService.getUploadableFilesFromGallery(imagePaths);
        await this.imagesInputComponent.uploadImages(images);
        this.logger.debug('Images were uploaded as Attachments from an android device');
      } catch (error) {
        this.logger.error('Failed to add an image from gallery: ', error);
      }
    } else {
      await document.getElementById('image').click();
    }
  }

  async loadPicturesFromGallery() {
    const fileInput = document.querySelector<HTMLInputElement>('#galleryFileInput');
    if (fileInput) {
      fileInput.click();
    }
  }

  onFileChange(files) {
    if (files.target.files && files.target.files.length) {
      this.spinnerService.activate('pulsating');
      this.imagesInputComponent.uploadImages(files.target.files).then(() => {
        this.spinnerService.deactivate();
      })
      .catch((error) => {
        this.toasterService.showErrorToaster('events.detail.notify.error.attachment_upload');
        this.logger.error('Error while uploading attachments', error);
        this.spinnerService.deactivate();
      })
    }
  }

  /**
   * Method called when added files from HMLT5 input (if cordova is unavailable)
   * @param data files added in the HTML5 file input
   */
  loadPicturesFromWeb(images) {
    this.imagesInputComponent.uploadImages(images);
    this.logger.debug('Images were uploaded as Attachments using the HTML5 input');
  }

  private async openImageEditor(image: string) {
    this.editingImage = image;
    this.showImageEditor = true;
  }

  public async editImage(attachment: Attachment) {
    const imageURL = this.imagesInputComponent.getPictureUrl(attachment, false);
    this.currentAttachmentId = attachment.id;
    const dataUrl = await this.imagesInputComponent.convertImageUrlToDataUrl(imageURL);
    this.openImageEditor(dataUrl);
  }

  public imageAfterEditing(image: string | null) {
    this.showImageEditor = false;
    if(image) {
      this.imagesInputComponent.replaceEditedImage(image, this.currentAttachmentId);
    }
  }

  /**
   * Method called on click of bin next to image to delete it
   * @param image file we want to delete
   */
  removeImage(image) {
    this.imagesInputComponent.removeAttachment(image);
  }

  annoyUser(): void {
    this.upgradeService.annoyUser('upgrade.feature.pictures');
  }
}
